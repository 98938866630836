import Crud from '../views/Status/Crud'
import Entity from '../views/Status/Add'

let routes = {
    path: '/status',
    component: resolve => require(['../layouts/full/LayoutFull'], resolve),
    children: [
        {
            path: '/status/add',
            name: 'NewStatus',
            component: Entity
        },
        {
            path: '/status/edit/:id?',
            name: 'EditStatus',
            component: Entity
        },
        {
            path: '/status',
            name: 'list-status',
            component: Crud,
            meta: {
                pageTitle: 'Lista de Status',
                breadcrumb: [
                    {
                        text: 'Perfis de Status',
                        active: true,
                    },
                ],
            },
        },
    ]
};


export default routes
