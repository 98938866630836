<template>
  <div>
    <div class="main-content">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="card-title">Nova Central de Atendimento</div>
            </div>
            <hr style="margin-top: -4px" />
            <div class="card-body">
              <div class="row">
                <div class="col-md-12">
                  <form action>
                    <div class="form-row">
                      <div class="form-group col-md-12">
                        <label for="Name">
                          <span class="required">*</span> Nome:
                        </label>
                        <input
                          autocomplete="new-password"
                          type="text"
                          id="Name"
                          class="form-control"
                          v-model="entity.nome"
                          placeholder=""
                        />
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-2">
                        <label>CEP</label>
                        <input
                          type="text"
                          class="form-control"
                          v-model="entity.cep"
                        />
                      </div>
                      <div class="col-md-4">
                        <label>Logradouro</label>
                        <input
                          type="text"
                          class="form-control"
                          v-model="entity.logradouro"
                        />
                      </div>
                      <div class="col-md-2">
                        <label>Número</label>
                        <input
                          type="text"
                          class="form-control"
                          v-model="entity.numero"
                        />
                      </div>
                      <div class="col-md-4">
                        <label>Complemento</label>
                        <input
                          type="text"
                          class="form-control"
                          v-model="entity.complemento"
                        />
                      </div>
                    </div>
                    <div class="row mt-1">
                      <div class="col-md-4">
                        <label>Bairro</label>
                        <input
                          type="text"
                          class="form-control"
                          v-model="entity.bairro"
                        />
                      </div>

                      <div class="form-group col-md-4">
                        <label><span class="required">*</span> Estado </label>
                        <v-select
                          :options="ufs"
                          :reduce="(ufs) => ufs.id"
                          :label="'uf_nome'"
                          v-model="entity.estado_id"
                          @input="getEstadoMunicipio"
                          class="vselect"
                        />
                      </div>

                      <div class="form-group col-md-4">
                        <label
                          ><span class="required">*</span> Município
                        </label>
                        <v-select
                          :options="municipios"
                          :reduce="(municipios) => municipios.id"
                          :label="'municipio_nome'"
                          v-model="entity.municipio_id"
                          class="vselect"
                        />
                      </div>
                    </div>

                    <span class="required_fields">
                      <span class="required">*</span>
                      <strong>Campos obrigatórios</strong>
                    </span>
                    <div class="block text-right">
                      <router-link to="/centrais" class="btn btn-back mr-1">
                        <i class="fa fa-arrow-left"></i> Voltar
                      </router-link>
                      <a class="btn btn-add" @click="save">
                        Salvar
                        <i class="fa fa-save"></i>
                      </a>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BaseCrud from "../../layouts/Base/BaseCrud";
import vSelect from "vue-select";

export default {
  data() {
    return {
      entity: {
        cidade: "",
        municipio_id: "",
        status: "1",
      },
      municipios: [],
      ufs: [],
    };
  },
  computed: {},
  methods: {
    save: function () {
      const self = this;
      let api = self.$store.state.api + "centrais";

      if (self.entity.central_id) {
        self.entity._method = "PUT";
        api += "/" + self.entity.central_id;
      }

      axios
        .post(api, self.entity)
        .then((response) => {
          self.$message(
            "Sucesso",
            `Informações guardadas com sucesso`,
            "success"
          );
          this.$router.push("/centrais");
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getEntity: function (id) {
      const self = this;
      const api = self.$store.state.api + "centrais/" + id;

      axios
        .get(api)
        .then((response) => {
          self.entity = response.data.data[0];
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },

    getMunicipios: function () {
      const self = this;
      let api =
        self.$store.state.api +
        "municipios?orderByAsc=municipio_nome&paginated=false";

      axios
        .get(api)
        .then((response) => {
          self.municipios = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },

    getUFs: function () {
      const self = this;
      let api =
        self.$store.state.api + "ufs?orderByAsc=uf_nome&paginated=false";

      axios
        .get(api)
        .then((response) => {
          self.ufs = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },

    getEstadoMunicipio() {
      const self = this;
      let api =
        self.$store.state.api +
        "municipios?orderByAsc=municipio_nome&paginated=false&uf_id=" +
        self.entity.estado_id;

      axios
        .get(api)
        .then((response) => {
          self.municipios = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
  },
  mounted: function () {
    const self = this;
    self.getUFs();
    self.getMunicipios();

    let id = self.$route.params.id;
    if (id) {
      self.getEntity(id);
    }
  },
  components: {
    BaseCrud,
    vSelect,
  },
};
</script>
<style scoped>
.profile_user {
  text-align: center;
}

.photo {
  display: block;
  margin-left: 5px;
  width: 18%;
  border-radius: 10px;
  max-width: 400px;
  max-height: 400px;
  object-fit: cover;
}

.btn-user:hover,
.btn-user[aria-expanded="true"] {
  background: linear-gradient(to right, #000, #666);
  color: #fff !important;
}

.photo-title {
  color: #000;
  font-size: 18px;
  display: block;
  margin-left: 5px;
  width: 18%;
}
</style>