import Crud from '../views/Atendimentos/Table'
import View from '../views/Atendimentos/View'
/* import Calendario from '../views/Transporte/Programacao/Calendario' */

let routes = {
    path: '/atendimentos',
    component: resolve => require(['../layouts/full/LayoutFull'], resolve),
    children: [
        {
            path: '/atendimentos',
            name: 'list-atendimentos',
            component: Crud,
            meta: {
                pageTitle: 'Atendimentos',
                breadcrumb: [
                    {
                        text: 'Atendimentos',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/atendimentos/view/:id?',
            name: 'VisualizarAtendimento',
            component: View
        },
        /* {
            path: '/programacoes/programacao/:id?',
            name: 'ProgramarSolicitacao',
            component: Entity
        }, */
        /* {
            path: '/calendario',
            name: 'calendario',
            component: Calendario
        }, */
    ]
};


export default routes
