<template>
  <div>
    <div class="row">
      <div class="col-12" align="right">
        <toggle-button @change="showFinisheds" :labels="{ checked: 'Sim', unchecked: 'Não' }" :width="55"
          v-model="showFinished" />
        Exibir finalizados
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-md-12">
        <span style="font-size: 16px">
          Atualização de Atendimentos em: <strong>{{ count }}</strong>
        </span>
        <base-crud :ref="'atendimentoCrud'" crudName="Atendimentos" newText="Novo Atendimento" :table="table"
          :columns="columns" :options="options" :endPoint="endPoint" :enableAdd="false" :enableEdit="false"
          :enableDelete="false" :extra="extra" @visualizar="visualizar" :enableReset="false" :dataForm="dataForm"
          :customFilters="customFilters" @filtered_da="filtered_da">
        </base-crud>
        <!--@approving="approving"
        @filtered_ds="filtered_ds" -->
      </div>
    </div>
  </div>
</template>

<script>
import "vue-loading-overlay/dist/vue-loading.css";
import BaseCrud from "../../layouts/Base/BaseCrud";

export default {
  data() {
    return {
      interval: "",
      count: 20,
      isLoading: true,
      fullPage: true,
      error: null,
      customFilters: [
        {
          name: "data_gravacao",
          type: "calendar",
          call: "filtered_da",
        },
      ],
      data_solicitacao: "",
      table: "atendimentoTable",
      showFinished: false,
      atendimentoCrud: {},
      pages: [],
      url: "",
      /* COLUNAS */
      columns: [
        "ocorrencia_id",
        "data_gravacao",
        "paciente_id",
        "status",
        "telefone",
        "qtd_messages",
        "actions",
      ],
      tableData: [
        "ocorrencia_id",
        "data_gravacao",
        "paciente_id",
        "telefone",
        "status",
        "qtd_messages",
      ],
      options: {
        filterByColumn: true,
        debounce: 1000,
        filterable: ["ocorrencia_id", "paciente_id", "status"],
        /* COLUNAS QUE PODEM SER ORDENADAS */
        sortable: ["ocorrencia_id", "data_gravacao", "paciente_id", "status"],
        /* ICONE DE ORDENACAO */
        sortIcon: {
          base: "fas",
          up: "fa-chevron-up",
          down: "fa-chevron-down",
          is: "fa-sort",
        },
        pagination: { chunk: 10, dropdown: false, nav: "scroll" },
        perPage: 10,
        perPageValues: [10, 25, 50, 100],
        /* TITULOS DAS COLUNAS DA TABELA */
        headings: {
          ocorrencia_id: "ID Atendimento",
          data_gravacao: "Data da Solicitação",
          paciente_id: "Paciente",
          status: "Status",
          telefone: "Telefone",
          qtd_messages: "",
          actions: "Ações",
        },

        texts: {
          filterBy: "Filtrar",
          defaultOption: "Selecione",
        },
        /* CAMPOS COM FILTRO DE LISTA */
        listColumns: {
          status: [
            {
              id: 0,
              text: "Solicitado",
            },
            {
              id: 1,
              text: "Em Andamento",
            },
            {
              id: 3,
              text: "Aguardando veículo",
            },
            {
              id: 4,
              text: "Cancelado",
            },
            {
              id: 6,
              text: "Despachando veículo",
            },
            {
              id: 7,
              text: "Veículo despachado",
            },
            {
              id: 8,
              text: "A caminho da unidade de saúde",
            },
            {
              id: 9,
              text: "Finalizado",
            },
          ],
        },
        templates: {
          /* tipo_transporte_id(h, row, index) {
            return row.tipo_transporte.tipo_transporte_nome;
          }, */
          /* paciente_id(h, row, index) {
            return row.paciente ? row.paciente.nome_paciente : "NÃO SE APLICA";
          }, */
          paciente_id(h, row, index) {
            return row.paciente_id
              ? row.paciente.nome_paciente
              : "SEM INFORMAÇÕES";
          },
          paciente_id(h, row, index) {
            return row.paciente_id
              ? row.paciente.nome_paciente
              : "SEM INFORMAÇÕES";
          },
          /* profissional_id(h, row, index) {
            return row.profissional.profissional_nome;
          }, */
          status(h, row, index) {
            return row.status_desc ? row.status_desc.nome : "Solicitado";
          },
          qtd_messages(h, row, index) {
            if (row.qtd_messages > 0) {
              return (
                <h5>
                  <span
                    style="background-color:#ce3a49; color:#fff;"
                    class="badge badge"
                  >
                    Mensagens Pendentes
                  </span>
                </h5>
              );
            }
          },
        },
        requestFunction(data) {
          this.$loading(true);
          let requestData = {};

          let query = this.$parent.$parent.$parent.query(data.query);
          requestData.params = data;

          if (
            this.$parent.$parent.$parent.data_gravacao != null &&
            this.$parent.$parent.$parent.data_gravacao != ""
          ) {
            requestData.params.data_gravacao =
              this.$parent.$parent.$parent.data_gravacao;
          }

          requestData.params.query = "";
          requestData.params.with = ["solicitante", "paciente", "status_desc"];

          return axios
            .get(this.url + "?" + query, requestData)
            .catch(
              function (err) {
                this.$loading(false);
                this.dispatch("error", e);
              }.bind(this)
            )
            .finally(
              function () {
                this.$loading(false);
              }.bind(this)
            );
        },
      },
      extra: [
        {
          id: 1,
          label: "",
          class: "ml-1 btn btn-crud view",
          title: "Visualizar",
          name: "visualizar",
          icon: "fas fa-eye",
        },
      ],
      endPoint: "ocorrencia",
      dataForm: {},
    };
  },
  components: {
    BaseCrud,
  },
  destroyed() {
    let intervl = 0;

    while (intervl < 100) {
      clearInterval(intervl);
      console.log("limpando intervals");
      intervl++;
    }
  },
  mounted: function () {
    // let intervl = 0;

    // while (intervl < 100) {
    //   clearInterval(intervl);
    //   console.log("limpando intervals");
    //   intervl++;
    // }

    this.interval = setInterval(() => {
      this.count--;
      if (this.count === 0) {
        const self = this;
        self.$refs.atendimentoCrud.$refs.table.refresh();
        this.count = 20;
      }
    }, 1000);
  },
  methods: {
    visualizar(props, row, index) {
      const self = this;
      this.$router.push("/atendimentos/view/" + props.row.ocorrencia_id);
    },
    filtered_da(date) {
      this.data_gravacao = date;
    },
    /* filtered_ds(date) {
      this.data_solicitacao = date
    }, */
    showFinisheds(res) {
      const self = this;
      self.$refs.atendimentoCrud.$refs.table.refresh();
    },
    /* approving(props, row, index) {
      const self = this;
      this.$router.push("/programacoes/programacao/" + props.row.id);
    }, */
    query(query) {
      let columns = {
        ocorrencia_id: "ocorrencia_id",
        data_gravacao: "data_gravacao",
        paciente_id: "paciente[nome_paciente]",
        status: "status",
      };
      if (this.showFinished != true) {
        var filters = "orderByDesc=data_gravacao&status=0,1,2,4,5,6,7,8&";
      } else {
        var filters = "orderByDesc=data_gravacao&";
      }
      /*       let filters = ''; */
      $.each(query, function (index, value) {
        filters += columns[index] + "=" + value + "&";
      });
      return filters;
    },
    openInput() {
      document.getElementById("fileImport").click();
    },
  },
};
</script>

<style scoped>
.VuePagination {
  text-align: center;
}

.uploading-image {
  display: flex;
  border-radius: 10px;
  width: 100px;
  height: 100px;
}
</style>