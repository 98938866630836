<template>
  <div>
    <div class="main-content">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="card-title">Adicionar Usuário</div>
            </div>
            <div class="card-body">
              <hr />
              <br />
              <div class="row">
                <div class="col-md-12">
                  <form action>
                    <div class="form-row">
                      <div class="form-group col-md-6">
                        <label for="Name">
                          <span class="required">*</span> Nome:
                        </label>
                        <input
                          autocomplete="new-password"
                          type="text"
                          id="Name"
                          class="form-control"
                          v-model="users.usuario_nome"
                          placeholder=""
                        />
                      </div>
                      <div class="form-group col-md-3">
                        <label for="Email">
                          <span class="required">*</span> Login:
                        </label>
                        <input
                          autocomplete="new-password"
                          class="form-control"
                          id="Email"
                          placeholder=""
                          type="text"
                          v-model="users.login"
                          :disabled="users.id"
                        />
                      </div>
                      <div class="form-group col-md-3">
                        <label for="Name">
                          <span class="required">*</span> Central:
                        </label>
                        <v-select
                          :options="centrais"
                          :reduce="(centrais) => centrais.central_id"
                          :label="'nome'"
                          v-model="users.central_id"
                          class="vselect"
                        />
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="form-group col-md-4">
                        <label for="inputUser">
                          <span class="required">*</span> Nível de acesso:
                        </label>
                        <select class="form-control" v-model="users.perfil_id">
                          <option
                            v-for="perfil_usuario in perfil_usuarios"
                            :key="perfil_usuario.perfil_id"
                            :value="perfil_usuario.perfil_id"
                          >
                            {{ perfil_usuario.perfil_nome }}
                          </option>
                        </select>
                      </div>
                      <div
                        class="form-group col-md-4"
                        v-if="users.perfil_id == 2"
                      >
                        <label for="">
                          <span class="required">*</span> CPF:
                        </label>
                        <input
                          autocomplete="new-password"
                          class="form-control"
                          type="text"
                          v-model="users.cpf"
                          v-mask="'999.999.999-99'"
                        />
                      </div>
                      <div class="form-group col-md-4">
                        <label for="Name"> Telefone: </label>
                        <input
                          type="text"
                          class="form-control"
                          v-mask="'(99) 99999-9999'"
                          v-model="users.telefone"
                          placeholder=""
                        />
                      </div>
                    </div>
                    <div class="row" v-if="$route.params.id">
                      <div class="col-md-12 mb-2">
                        <button
                          type="button"
                          class="btn btn-add"
                          @click="toggleFormSenha"
                        >
                          <i class="fas fa-key"></i> Alterar Senha
                        </button>
                      </div>
                    </div>
                    <div class="form-row" v-if="change_password">
                      <div class="form-group col-md-6">
                        <label for="password">
                          <span class="required">*</span> Senha:
                        </label>
                        <input
                          autocomplete="new-password"
                          type="password"
                          id="password"
                          class="form-control"
                          v-model="users.senha"
                          placeholder=""
                        />
                      </div>
                      <div class="form-group col-md-6">
                        <label for="confirmPassword">
                          <span class="required">*</span> Confirme a senha:
                        </label>
                        <input
                          autocomplete="new-password"
                          class="form-control"
                          id="confirmPassword"
                          placeholder=""
                          type="password"
                          v-model="users.senha_confirmacao"
                        />
                      </div>
                    </div>

                    <div class="form-group">
                      <label for="userPic"> Foto do Perfil </label>
                      <div class="user-img">
                        <img
                          v-if="previewImage"
                          :src="previewImage"
                          class="photo"
                        />
                        <img v-else class="photo" />
                      </div>
                      <input
                        type="file"
                        class=""
                        id="userPic"
                        accept="image/*"
                        name="userPic"
                        @change="uploadImage"
                        placeholder
                      />
                    </div>
                    <span class="required_fields">
                      <span class="required">*</span>
                      <strong>Campos obrigatórios</strong>
                    </span>
                    <div class="block text-right">
                      <router-link to="/users" class="btn btn-back mr-1">
                        <i class="fa fa-arrow-left"></i> Voltar
                      </router-link>
                      <a class="btn btn-add" @click="save">
                        Salvar
                        <i class="fa fa-save"></i>
                      </a>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BaseCrud from "../../layouts/Base/BaseCrud";
import vSelect from "vue-select";

export default {
  name: "imageUpload",
  data() {
    return {
      companies: [],
      centrais: [],
      departments: [],
      change_password: true,
      users: {
        usuario_nome: "",
        login: "",
        perfil_id: "",
        senha: "",
        senha_confirmacao: "",
        telefone: "",
      },
      companies: {},
      departments: {},
      perfil_usuarios: [],
      centrais: [],

      errors: undefined,

      previewImage: null,
    };
  },
  computed: {},
  methods: {
    toggleFormSenha() {
      const self = this;
      if (self.change_password) {
        self.change_password = false;
      } else {
        self.change_password = true;
      }
    },
    makeFormData: function () {
      const self = this;
      let fd = new FormData();

      let photo = document.getElementById("userPic");

      fd.append("usuario_nome", self.users.usuario_nome);
      fd.append("login", self.users.login);
      fd.append("perfil_id", self.users.perfil_id);
      fd.append("senha", self.users.senha);
      fd.append("senha_confirmacao", self.users.senha_confirmacao);
      fd.append(
        "logradouro",
        self.users.logradouro ? self.users.logradouro : ""
      );
      fd.append("numero", self.users.numero ? self.users.numero : "");
      fd.append("cep", self.users.cep ? self.users.cep : "");
      fd.append("cidade", self.users.cidade ? self.users.cidade : "");
      fd.append("uf", self.users.uf ? self.users.uf : "");
      fd.append("cpf", self.users.cpf ? self.users.cpf : "");
      fd.append("telefone", self.users.telefone ? self.users.telefone : "");
      fd.append(
        "central_id",
        self.users.central_id ? self.users.central_id : ""
      );
      fd.append(
        "complemento",
        self.users.complemento ? self.users.complemento : ""
      );
      fd.append("bairro", self.users.bairro ? self.users.bairro : "");
      fd.append(
        "deficiencia",
        self.users.deficiencia ? self.users.deficiencia : ""
      );

      fd.append("_method", "POST");

      fd.append("foto", photo.files[0] ? photo.files[0] : "");

      if (self.users.id) {
        fd.append("id", self.users.id);
        fd.append("_method", "PUT");
      }

      return fd;
    },
    save: function () {
      const self = this;
      let api = self.$store.state.api + "users";

      let fd = self.makeFormData();

      if (self.users.id) {
        api += "/" + self.users.id;
      }

      axios
        .post(api, fd)
        .then((response) => {
          self.$message(
            "Sucesso",
            `Informações guardadas com sucesso`,
            "success"
          );
          this.$router.push("/users");
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getUsers: function (id) {
      const self = this;
      const api = self.$store.state.api + "users/" + id;

      axios
        .get(api)
        .then((response) => {
          self.users = response.data.data[0];
          self.users.password = "";
          self.change_password = false;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getPerfilUsuario: function () {
      const self = this;
      let api =
        self.$store.state.api +
        "perfil_usuarios?paginated=false&status=1&orderByAsc=perfil_id";

      axios
        .get(api)
        .then((response) => {
          self.perfil_usuarios = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    uploadImage(e) {
      const image = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = (e) => {
        this.previewImage = e.target.result;
        console.log(this.previewImage);
      };
    },
    getCentrais() {
      const self = this;
      let api = self.$store.state.api + "centrais?paginated=false";

      axios
        .get(api)
        .then((response) => {
          self.centrais = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
  },
  mounted: function () {
    const self = this;

    self.getPerfilUsuario();
    self.getCentrais();

    let id = self.$route.params.id;
    if (id) {
      self.change_password = false;
      self.getUsers(id);
    }
  },
  components: {
    BaseCrud,
    vSelect,
  },
};
</script>
<style scoped>
.profile_user {
  text-align: center;
}
.photo {
  display: block;
  margin-left: 5px;
  width: 18%;
  border-radius: 10px;
  max-width: 400px;
  max-height: 400px;
  object-fit: cover;
}
.btn-user:hover,
.btn-user[aria-expanded="true"] {
  background: linear-gradient(to right, #000, #666);
  color: #fff !important ;
}
.photo-title {
  color: #000;
  font-size: 18px;
  display: block;
  margin-left: 5px;
  width: 18%;
}
</style>