let env = {};

// env.baseURL = 'http://192.168.15.100:8000/api/v1/';
// env.normalURL = 'http://192.168.15.100:8000/';
// env.wsHost = '192.168.15.100';

// env.baseURL = 'http://localhost:8000/api/v1/';
// env.normalURL = 'http://localhost:8000/';
// env.wsHost = 'localhost';

env.baseURL = 'https://api.app-menoclima.ares-saude.com.br/api/v1/';
env.normalURL = 'https://api.app-menoclima.ares-saude.com.br/';
env.wsHost = 'websocket.app-menoclima.ares-saude.com.br';

// env.baseURL = 'http://api.transporte_rio.it4d.com.br/api/v1/';
// env.normalURL = 'http://api.transporte_rio.it4d.com.br/';
// env.wsHost = 'localhost';

export default env
