<template>
  <div>
    <div class="main-content">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="card-title" v-if="loaded">
                Atendimento #{{ atendimento.ocorrencia_id }}
              </div>
              <div class="card-title"><span id="timer"></span></div>
            </div>
            <div class="card-body" v-if="loaded">
              <hr />
              <br />
              <div class="row">
                <div class="col-md-12">
                  <form action>
                    <div class="form-row">
                      <div class="form-group col-md-12">
                        <GmapMap :center="{ lat: position.lat, lng: position.lng }" :options="{
                          zoomControl: true,
                          streetViewControl: false,
                          mapTypeControl: false,
                          fullscreenControl: false,
                        }" :zoom="16" map-type-id="roadmap" style="
                            width: auto;
                            height: 250px;
                            border-radius: 50px !important;
                          ">
                          <GmapMarker :position="position"></GmapMarker>
                        </GmapMap>
                        <span style="text-align: center !important">Coordenadas: Latitude {{ position.lat }} Longitude
                          {{ position.lng }}</span>
                      </div>
                    </div>

                    <div class="form-row">
                      <div class="form-group col-md-2">
                        <label for="Email">
                          <span class="required">*</span> Data Solicitação:
                        </label>
                        <input class="form-control" id="Email" placeholder="" type="text"
                          v-model="atendimento.data_gravacao" :disabled="true" />
                      </div>

                      <div class="form-group col-md-3">
                        <label for="Email">
                          <span class="required">*</span> Solicitante:
                        </label>
                        <input class="form-control" id="Email" placeholder="" type="text"
                          v-model="atendimento.solicitante.usuario_nome" :disabled="true" />
                      </div>
                      <div class="form-group col-md-2">
                        <label for="Email">
                          <span class="required">*</span> CPF do Solicitante:
                        </label>
                        <input class="form-control" id="Email" placeholder="" type="text"
                          v-model="atendimento.solicitante.cpf" :disabled="true" />
                      </div>

                      <div class="form-group col-md-2">
                        <label for="Email">
                          <span class="required">*</span> IP Solicitante:
                        </label>
                        <input class="form-control" id="Email" placeholder="" type="text"
                          v-model="atendimento.ip_solicitante" :disabled="true" />
                      </div>

                      <div class="form-group col-md-3">
                        <label style=""> Status Solicitação: </label>
                        <select class="form-select custom-input" type="select" v-model="atendimento.status">
                          <option value="0">Solicitado</option>
                          <option v-for="stats in status" :value="stats.status_id" :key="stats.status_id">
                            {{ stats.nome }}
                          </option>
                        </select>
                      </div>
                    </div>

                    <div class="form-row">
                      <div class="form-group col-md-6">
                        <label for="Email">
                          <span class="required">*</span> Nome Paciente:
                        </label>
                        <input class="form-control" id="Email" placeholder="" type="text" :value="atendimento.paciente
                          ? atendimento.paciente.nome_paciente
                          : atendimento.nome_vitima
                          " :disabled="true" />
                      </div>

                      <div class="form-group col-md-3">
                        <label for="Name">
                          <span class="required">*</span>
                          {{
                            atendimento.data_nascimento_vitima
                              ? "Idade"
                              : "Idade Aparente"
                          }}:
                        </label>
                        <input type="text" id="Name" class="form-control" :value="atendimento.paciente
                          ? atendimento.paciente.idade
                          : atendimento.idade_vitima
                          " placeholder="" :disabled="true" />
                      </div>

                      <div class="form-group col-md-3">
                        <label for="Name">
                          <span class="required">*</span> Sexo:
                        </label>
                        <input type="text" id="Name" class="form-control" :value="atendimento.paciente
                          ? atendimento.paciente.sexo
                          : atendimento.sexo_vitima
                          " placeholder="" :disabled="true" />
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="form-group col-md-3">
                        <label for="Name">
                          <span class="required">*</span> CEP:
                        </label>
                        <input type="text" id="Name" class="form-control" :value="atendimento.cep" placeholder=""
                          :disabled="true" />
                      </div>
                      <div class="form-group col-md-3">
                        <label for="Name">
                          <span class="required">*</span> Logradouro:
                        </label>
                        <input type="text" id="Name" class="form-control" :value="atendimento.logradouro" placeholder=""
                          :disabled="true" />
                      </div>
                      <div class="form-group col-md-3">
                        <label for="Name">
                          <span class="required">*</span> Número:
                        </label>
                        <input type="text" id="Name" class="form-control" :value="atendimento.numero" placeholder=""
                          :disabled="true" />
                      </div>
                      <div class="form-group col-md-3">
                        <label for="Name">
                          <span class="required">*</span> Bairro:
                        </label>
                        <input type="text" id="Name" class="form-control" :value="atendimento.bairro" placeholder=""
                          :disabled="true" />
                      </div>
                      <div class="form-group col-md-3">
                        <label for="Name">
                          <span class="required">*</span> Cidade:
                        </label>
                        <input type="text" id="Name" class="form-control" :value="atendimento.cidade" placeholder=""
                          :disabled="true" />
                      </div>
                      <div class="form-group col-md-2">
                        <label for="Name">
                          <span class="required">*</span> UF:
                        </label>
                        <input type="text" id="Name" class="form-control" :value="atendimento.uf" placeholder=""
                          :disabled="true" />
                      </div>
                      <div class="form-group col-md-4">
                        <label for="Name">
                          <span class="required">*</span> Complemento:
                        </label>
                        <input type="text" id="Name" class="form-control" :value="atendimento.complemento"
                          placeholder="" :disabled="true" />
                      </div>
                      <div class="form-group col-md-3">
                        <label for="Name">
                          <span class="required">*</span> Telefone:
                        </label>
                        <input type="text" id="Name" class="form-control" :value="atendimento.paciente
                          ? atendimento.paciente.telefone
                          : ''
                          " placeholder="" :disabled="true" />
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="form-group col-md-12">
                        <label for="inputField" style="font-size: 0.857rem !important" class="col-form-label">Queixa
                          Principal:</label>
                        <div class="custom-input-container">
                          <textarea :disabled="true" v-model="atendimento.queixa_principal" style="
                              height: 83px;
                              background-color: #efefef !important;
                            " maxlength="260" class="custom-input" />
                        </div>
                      </div>
                    </div>
                    <div class="row mt-5 mb-5" v-if="atendimento.form">
                      <div class="col-12 mb-2">
                        <h2>Quais dos seguintes sintomas e em que grau você diria que se sente atualmente?</h2>
                      </div>
                      <div class="col-12">
                        <div class="row">
                          <div class="col-12 bold mb-3" style="font-size: 18px;">
                            Falta de ar, suor, calores:
                          </div>
                          <div class="col-2">
                            <input type="radio" disabled v-model="atendimento.form.a" value="0" name="0.1" id="0.1">
                            <br>
                            <label for="0.1">Nenhum</label>
                          </div>
                          <div class="col-2">
                            <input type="radio" disabled v-model="atendimento.form.a" value="1" name="0.2" id="0.2">
                            <br>
                            <label for="0.2">Leves</label>
                          </div>
                          <div class="col-3">
                            <input type="radio" disabled v-model="atendimento.form.a" value="2" name="0.3" id="0.3">
                            <br>
                            <label for="0.3">Moderados</label>
                          </div>
                          <div class="col-2">
                            <input type="radio" disabled v-model="atendimento.form.a" value="3" name="0.4" id="0.4">
                            <br>
                            <label for="0.4">Severos</label>
                          </div>
                          <div class="col-3">
                            <input type="radio" disabled v-model="atendimento.form.a" value="4" name="0.5" id="0.5">
                            <br>
                            <label for="0.5">Extremamente Severos</label>
                          </div>
                        </div>
                        <div class="row mt-3">
                          <div class="col-12 bold mb-3" style="font-size: 18px;">
                            Mal estar do coração (batidas do coração diferentes, saltos nas batidas, batidas mais
                            longas,
                            pressão):
                          </div>
                          <div class="col-2">
                            <input type="radio" disabled v-model="atendimento.form.b" value="0" name="1.1" id="1.1">
                            <br>
                            <label for="1.1">Nenhum</label>
                          </div>
                          <div class="col-2">
                            <input type="radio" disabled v-model="atendimento.form.b" value="1" name="1.2" id="1.2">
                            <br>
                            <label for="1.2">Leves</label>
                          </div>
                          <div class="col-3">
                            <input type="radio" disabled v-model="atendimento.form.b" value="2" name="1.3" id="1.3">
                            <br>
                            <label for="1.3">Moderados</label>
                          </div>
                          <div class="col-2">
                            <input type="radio" disabled v-model="atendimento.form.b" value="3" name="1.4" id="1.4">
                            <br>
                            <label for="1.4">Severos</label>
                          </div>
                          <div class="col-3">
                            <input type="radio" disabled v-model="atendimento.form.b" value="4" name="1.5" id="1.5">
                            <br>
                            <label for="1.5">Extremamente Severos</label>
                          </div>
                        </div>

                        <!-- <div class="row mt-3">
                          <div class="col-12 bold mb-3" style="font-size: 18px;">
                            Mal estar do coração (batidas do coração diferentes, saltos nas batidas, batidas mais
                            longas,
                            pressão):
                          </div>
                          <div class="col-2">
                            <input type="radio" disabled value="0" v-model="atendimento.form.c" name="2.1" id="2.1">
                            <br>
                            <label for="2.1">Nenhum</label>
                          </div>
                          <div class="col-2">
                            <input type="radio" disabled value="1" v-model="atendimento.form.c" name="2.2" id="2.2">
                            <br>
                            <label for="2.2">Leves</label>
                          </div>
                          <div class="col-3">
                            <input type="radio" disabled value="2" v-model="atendimento.form.c" name="2.3" id="2.3">
                            <br>
                            <label for="2.3">Moderados</label>
                          </div>
                          <div class="col-2">
                            <input type="radio" disabled value="3" v-model="atendimento.form.c" name="2.4" id="2.4">
                            <br>
                            <label for="2.4">Severos</label>
                          </div>
                          <div class="col-3">
                            <input type="radio" disabled value="4" v-model="atendimento.form.c" name="2.5" id="2.5">
                            <br>
                            <label for="2.5">Extremamente Severos</label>
                          </div>
                        </div> -->

                        <div class="row mt-3">
                          <div class="col-12 bold mb-3" style="font-size: 18px;">
                            Problemas de sono (dificuldades em conciliar o sono, em dormir a noite toda e acordar muito
                            cedo):
                          </div>
                          <div class="col-2">
                            <input type="radio" disabled value="0" v-model="atendimento.form.d" name="3.1" id="3.1">
                            <br>
                            <label for="3.1">Nenhum</label>
                          </div>
                          <div class="col-2">
                            <input type="radio" disabled value="1" v-model="atendimento.form.d" name="3.2" id="3.2">
                            <br>
                            <label for="3.2">Leves</label>
                          </div>
                          <div class="col-3">
                            <input type="radio" disabled value="2" v-model="atendimento.form.d" name="3.3" id="3.3">
                            <br>
                            <label for="3.3">Moderados</label>
                          </div>
                          <div class="col-2">
                            <input type="radio" disabled value="3" v-model="atendimento.form.d" name="3.4" id="3.4">
                            <br>
                            <label for="3.4">Severos</label>
                          </div>
                          <div class="col-3">
                            <input type="radio" disabled value="4" v-model="atendimento.form.d" name="3.5" id="3.5">
                            <br>
                            <label for="3.5">Extremamente Severos</label>
                          </div>
                        </div>

                        <div class="row mt-3">
                          <div class="col-12 bold mb-3" style="font-size: 18px;">
                            Estado de ânimo depressivo (sentir-se para baixo, triste, a ponto das lágrimar, falta de
                            vontade,
                            instabilidade de humor):
                          </div>
                          <div class="col-2">
                            <input type="radio" disabled value="0" v-model="atendimento.form.e" name="4.1" id="4.1">
                            <br>
                            <label for="4.1">Nenhum</label>
                          </div>
                          <div class="col-2">
                            <input type="radio" disabled value="1" v-model="atendimento.form.e" name="4.2" id="4.2">
                            <br>
                            <label for="4.2">Leves</label>
                          </div>
                          <div class="col-3">
                            <input type="radio" disabled value="2" v-model="atendimento.form.e" name="4.3" id="4.3">
                            <br>
                            <label for="4.3">Moderados</label>
                          </div>
                          <div class="col-2">
                            <input type="radio" disabled value="3" v-model="atendimento.form.e" name="4.4" id="4.4">
                            <br>
                            <label for="4.4">Severos</label>
                          </div>
                          <div class="col-3">
                            <input type="radio" disabled value="4" v-model="atendimento.form.e" name="4.5" id="4.5">
                            <br>
                            <label for="4.5">Extremamente Severos</label>
                          </div>
                        </div>

                        <div class="row mt-3">
                          <div class="col-12 bold mb-3" style="font-size: 18px;">
                            Irritabilidade (sentir-se nervosa, tensa, agressiva):
                          </div>
                          <div class="col-2">
                            <input type="radio" disabled value="0" v-model="atendimento.form.f" name="5.1" id="5.1">
                            <br>
                            <label for="5.1">Nenhum</label>
                          </div>
                          <div class="col-2">
                            <input type="radio" disabled value="1" v-model="atendimento.form.f" name="5.2" id="5.2">
                            <br>
                            <label for="5.2">Leves</label>
                          </div>
                          <div class="col-3">
                            <input type="radio" disabled value="2" v-model="atendimento.form.f" name="5.3" id="5.3">
                            <br>
                            <label for="5.3">Moderados</label>
                          </div>
                          <div class="col-2">
                            <input type="radio" disabled value="3" v-model="atendimento.form.f" name="5.4" id="5.4">
                            <br>
                            <label for="5.4">Severos</label>
                          </div>
                          <div class="col-3">
                            <input type="radio" disabled value="4" v-model="atendimento.form.f" name="5.5" id="5.5">
                            <br>
                            <label for="5.5">Extremamente Severos</label>
                          </div>
                        </div>

                        <div class="row mt-3">
                          <div class="col-12 bold mb-3" style="font-size: 18px;">
                            Ansiedade (impaciência, pânico):
                          </div>
                          <div class="col-2">
                            <input type="radio" disabled value="0" v-model="atendimento.form.g" name="6.1" id="6.1">
                            <br>
                            <label for="6.1">Nenhum</label>
                          </div>
                          <div class="col-2">
                            <input type="radio" disabled value="1" v-model="atendimento.form.g" name="6.2" id="6.2">
                            <br>
                            <label for="6.2">Leves</label>
                          </div>
                          <div class="col-3">
                            <input type="radio" disabled value="2" v-model="atendimento.form.g" name="6.3" id="6.3">
                            <br>
                            <label for="6.3">Moderados</label>
                          </div>
                          <div class="col-2">
                            <input type="radio" disabled value="3" v-model="atendimento.form.g" name="6.4" id="6.4">
                            <br>
                            <label for="6.4">Severos</label>
                          </div>
                          <div class="col-3">
                            <input type="radio" disabled value="4" v-model="atendimento.form.g" name="6.5" id="6.5">
                            <br>
                            <label for="6.5">Extremamente Severos</label>
                          </div>
                        </div>

                        <div class="row mt-3">
                          <div class="col-12 bold mb-3" style="font-size: 18px;">
                            Esgotamento físico e mental (queda geral em seu desempenho):
                          </div>
                          <div class="col-2">
                            <input type="radio" disabled value="0" v-model="atendimento.form.h" name="7.1" id="7.1">
                            <br>
                            <label for="7.1">Nenhum</label>
                          </div>
                          <div class="col-2">
                            <input type="radio" disabled value="1" v-model="atendimento.form.h" name="7.2" id="7.2">
                            <br>
                            <label for="7.2">Leves</label>
                          </div>
                          <div class="col-3">
                            <input type="radio" disabled value="2" v-model="atendimento.form.h" name="7.3" id="7.3">
                            <br>
                            <label for="7.3">Moderados</label>
                          </div>
                          <div class="col-2">
                            <input type="radio" disabled value="3" v-model="atendimento.form.h" name="7.4" id="7.4">
                            <br>
                            <label for="7.4">Severos</label>
                          </div>
                          <div class="col-3">
                            <input type="radio" disabled value="4" v-model="atendimento.form.h" name="7.5" id="7.5">
                            <br>
                            <label for="7.5">Extremamente Severos</label>
                          </div>
                        </div>

                        <div class="row mt-3">
                          <div class="col-12 bold mb-3" style="font-size: 18px;">
                            Problemas sexuais (falta no desejo sexual, na atividade e satisfação):
                          </div>
                          <div class="col-2">
                            <input type="radio" disabled value="0" v-model="atendimento.form.i" name="8.1" id="8.1">
                            <br>
                            <label for="8.1">Nenhum</label>
                          </div>
                          <div class="col-2">
                            <input type="radio" disabled value="1" v-model="atendimento.form.i" name="8.2" id="8.2">
                            <br>
                            <label for="8.2">Leves</label>
                          </div>
                          <div class="col-3">
                            <input type="radio" disabled value="2" v-model="atendimento.form.i" name="8.3" id="8.3">
                            <br>
                            <label for="8.3">Moderados</label>
                          </div>
                          <div class="col-2">
                            <input type="radio" disabled value="3" v-model="atendimento.form.i" name="8.4" id="8.4">
                            <br>
                            <label for="8.4">Severos</label>
                          </div>
                          <div class="col-3">
                            <input type="radio" disabled value="4" v-model="atendimento.form.i" name="8.5" id="8.5">
                            <br>
                            <label for="8.5">Extremamente Severos</label>
                          </div>
                        </div>

                        <div class="row mt-3">
                          <div class="col-12 bold mb-3" style="font-size: 18px;">
                            Problemas de bexiga (dificuldade de urinar, incontinência, desejo excessivo de urinar):
                          </div>
                          <div class="col-2">
                            <input type="radio" disabled value="0" v-model="atendimento.form.j" name="9.1" id="9.1">
                            <br>
                            <label for="9.1">Nenhum</label>
                          </div>
                          <div class="col-2">
                            <input type="radio" disabled value="1" v-model="atendimento.form.j" name="9.2" id="9.2">
                            <br>
                            <label for="9.2">Leves</label>
                          </div>
                          <div class="col-3">
                            <input type="radio" disabled value="2" v-model="atendimento.form.j" name="9.3" id="9.3">
                            <br>
                            <label for="9.3">Moderados</label>
                          </div>
                          <div class="col-2">
                            <input type="radio" disabled value="3" v-model="atendimento.form.j" name="9.4" id="9.4">
                            <br>
                            <label for="9.4">Severos</label>
                          </div>
                          <div class="col-3">
                            <input type="radio" disabled value="4" v-model="atendimento.form.j" name="9.5" id="9.5">
                            <br>
                            <label for="9.5">Extremamente Severos</label>
                          </div>
                        </div>

                        <div class="row mt-3">
                          <div class="col-12 bold mb-3" style="font-size: 18px;">
                            Ressecamento vaginal (sensação de ressecamento, ardência e problemas durante a relação
                            sexual):
                          </div>
                          <div class="col-2">
                            <input type="radio" disabled value="0" v-model="atendimento.form.k" name="10.1" id="10.1">
                            <br>
                            <label for="10.1">Nenhum</label>
                          </div>
                          <div class="col-2">
                            <input type="radio" disabled value="1" v-model="atendimento.form.k" name="10.2" id="10.2">
                            <br>
                            <label for="10.2">Leves</label>
                          </div>
                          <div class="col-3">
                            <input type="radio" disabled value="2" v-model="atendimento.form.k" name="10.3" id="10.3">
                            <br>
                            <label for="10.3">Moderados</label>
                          </div>
                          <div class="col-2">
                            <input type="radio" disabled value="3" v-model="atendimento.form.k" name="10.4" id="10.4">
                            <br>
                            <label for="10.4">Severos</label>
                          </div>
                          <div class="col-3">
                            <input type="radio" disabled value="4" v-model="atendimento.form.k" name="10.5" id="10.5">
                            <br>
                            <label for="10.5">Extremamente Severos</label>
                          </div>
                        </div>

                        <div class="row mt-3">
                          <div class="col-12 bold mb-3" style="font-size: 18px;">
                            Problemas musculares e nas articulações (dores reumáticas e nas articulações):
                          </div>
                          <div class="col-2">
                            <input type="radio" disabled value="0" v-model="atendimento.form.l" name="11.1" id="11.1">
                            <br>
                            <label for="11.1">Nenhum</label>
                          </div>
                          <div class="col-2">
                            <input type="radio" disabled value="1" v-model="atendimento.form.l" name="11.2" id="11.2">
                            <br>
                            <label for="11.2">Leves</label>
                          </div>
                          <div class="col-3">
                            <input type="radio" disabled value="2" v-model="atendimento.form.l" name="11.3" id="11.3">
                            <br>
                            <label for="11.3">Moderados</label>
                          </div>
                          <div class="col-2">
                            <input type="radio" disabled value="3" v-model="atendimento.form.l" name="11.4" id="11.4">
                            <br>
                            <label for="11.4">Severos</label>
                          </div>
                          <div class="col-3">
                            <input type="radio" disabled value="4" v-model="atendimento.form.l" name="11.5" id="11.5">
                            <br>
                            <label for="11.5">Extremamente Severos</label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <label>Outras anotações</label>
                        <textarea class="form-control" v-model="atendimento.desfecho" cols="30" rows="10"></textarea>
                      </div>
                    </div>
                    <div class="d-flex justify-content-center align-items-center">
                      <div class="form-group">
                        <button @click="toggleCollapse" class="btn" type="button">
                          Exibir Histórico de Status
                          <i class="fas fa-chevron-down ml-1"></i>
                        </button>
                      </div>
                    </div>
                    <div class="historico-container" v-if="!showCollapse">
                      <ul>
                        <li v-for="historico in historico_status" :key="historico.id" class="historico-item">
                          <div class="status-info">
                            <p>
                              <span class="status-label">Status Anterior:</span>
                              <span class="status-name">
                                {{ historico.nome_status_anterior }}
                              </span>
                            </p>
                            <p>
                              <span class="status-label">Status Atual:</span>
                              <span class="status-name">
                                {{ historico.nome_status_atual }}
                              </span>
                            </p>
                          </div>
                          <div class="data-info">
                            <p>
                              <span class="status-label">Data da Alteração:</span>
                              <span class="status-name">
                                {{ historico.data_alteracao_f }}
                              </span>
                            </p>
                            <span class="status-label">Usuário:</span>
                            <span class="status-name">
                              {{ historico.usuario.usuario_nome }}
                            </span>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div class="block text-right mt-3">
                      <button type="button" class="btn btn-back mr-1" data-toggle="modal"
                        data-target="#modalTelemedicinaCenter">
                        <i class="fas fa-comments"></i> Enviar Link Telemedicina
                      </button>
                      <button type="button" class="btn btn-back mr-1" data-toggle="modal" data-target="#modalChatCenter"
                        @click="rolar(), readMessages()">
                        <i class="fas fa-comments"></i> Chat
                      </button>
                      <router-link to="/atendimentos" class="btn btn-back mr-1">
                        <i class="fa fa-arrow-left"></i> Voltar
                      </router-link>
                      <a class="btn btn-add" @click="save">
                        Salvar
                        <i class="fa fa-save"></i>
                      </a>
                      <button type="button" class="btn btn-success ml-1" style="
                          height: 30px !important;
                          padding: 6px 10px 6px !important;
                          border: 2px solid;
                        " @click="finalizar()">
                        <i class="fas fa-check"></i> Finalizar Atendimento
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal Chat -->
    <div class="modal fade" id="modalChatCenter" tabindex="-1" role="dialog" aria-labelledby="modalChatCenterTitle"
      aria-hidden="true" v-if="loaded">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="modalChatLongTitle">
              Chat com o solicitante
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="chat">
              <div v-if="atendimento">
                <div class="chat-header clearfix">
                  <div class="row">
                    <div class="col-lg-6">
                      <a href="javascript:void(0);" data-toggle="modal" data-target="#view_info">
                        <img :src="atendimento.solicitante.foto" alt="avatar" />
                      </a>
                      <div class="chat-about">
                        <h6 class="m-b-0">
                          {{ atendimento.solicitante.usuario_nome }}
                        </h6>
                        <!-- <small>Online</small> -->
                      </div>
                    </div>
                  </div>
                </div>
                <div class="chat-history" id="chat-messages" style="overflow: auto; max-height: 500px">
                  <ul class="m-b-0">
                    <li class="clearfix" v-for="message in messages" :key="message.mensagem_id">
                      <div class="message-data" :class="user.usuario_id == message.usuario_id
                        ? 'text-right'
                        : 'text-left'
                        ">
                        <span class="message-data-time">{{
                          moment(message.enviado_em).fromNow()
                          }}</span>
                      </div>
                      <div class="message" :class="user.usuario_id == message.usuario_id
                        ? 'other-message float-right'
                        : 'my-message float-left'
                        ">
                        {{ message.mensagem }}
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="chat-message clearfix">
                  <div class="input-group mb-0">
                    <div class="input-group-prepend" style="cursor: pointer" @click="sendMessage"
                      v-if="atendimento.status != 9">
                      <span class="input-group-text"><i class="fas fa-paper-plane"></i></span>
                    </div>
                    <input :disabled="atendimento.status === 9 || sending_message" type="text" class="form-control"
                      placeholder="Mensagem..." v-model="message" v-on:keyup.enter="sendMessage" id="message_area" />
                  </div>
                </div>
                <div v-if="atendimento.status === 9" class="finalized-message">
                  O atendimento está finalizado. Não é possível enviar mais
                  mensagens.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal Telemedicina -->
    <div class="modal fade" id="modalTelemedicinaCenter" tabindex="-1" role="dialog"
      aria-labelledby="modalTelemedicinaCenterTitle" aria-hidden="true" v-if="loaded">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="modalChatLongTitle">
              Enviar o link de telemedicina
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="chat">
              <div v-if="atendimento">

                <div class="row">
                  <div class="col-md-12">
                    <label for="">Insira o link</label>
                    <input type="text" class="form-control" v-model="link">
                  </div>
                </div>

                <div class="row justify-content-center mt-2">
                  <div class="col-md-12">
                    <button class="btn btn-add" @click="enviarLink">Enviar Link</button>
                  </div>
                </div>


              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Fim Modal Chat -->
  </div>
</template>
<script>
import "vue-loading-overlay/dist/vue-loading.css";
import moment from "moment";
export default {
  components: {},
  data() {
    return {
      websocket_listening: false,
      showCollapse: true,
      statusList: [],
      historico_status: [],
      sending_message: false,
      moment: moment,
      loaded: false,
      interval: "",
      link: "",
      messages: [],
      status: [],
      message: "",
      position: {
        lat: 0,
        lng: 0,
      },
      isLoading: true,
      fullPage: true,
      atendimento: {
        data_gravacao: "",
        paciente: "",
        ocorrencia_id: "",
        latitude: 0,
        longitude: 0,
        desfecho: "",
      },
      user: {},
      tempo: 0,
      minutos: 0,
      segundos: 0,
      horas: 0,
    };
  },
  destroyed() {
    clearInterval(this.interval);
  },
  computed: {},
  mounted() {
    const self = this;
    self.$loading(true);

    self.user = self.$store.state.user;
    const ocorrenciaId = self.$route.params.id;

    if (ocorrenciaId) {
      self.getAtendimento(ocorrenciaId);
      self.getStatus();
      // self.countdown();
      self.interval = setInterval(self.countdown, 1000);
    }
  },
  beforeDestroy() {
    const self = this;
    Echo.leave("ocorrencia." + self.atendimento.ocorrencia_id);

    console.log("teste destroy");
  },
  methods: {
    enviarLink() {
      const self = this;
      swal({
        title: "Tem certeza que deseja enviar o link da sala de vídeochamada?",
        text: "Esta ação não pode ser desfeita.",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#DD6B55",
        confirmButtonText: "Sim, deletar!",
        showLoaderOnConfirm: true,
        buttons: {
          catch: {
            text: "Não",
            value: "cancel",
            className: "btn btn-danger",
          },
          confirm: {
            text: "Sim",
            value: "confirm",
            className: "btn btn-success",
          },
        },
      }).then((value) => {
        switch (value) {
          case "cancel":
            swal("Ok!", "Não enviado.", "info");
            break;

          case "confirm":
            self.$loading(true);
            let api = self.$store.state.api + "enviar-link";

            const payload = {
              link: self.link,
              id: self.atendimento.ocorrencia_id
            };

            axios
              .post(api, payload)
              .then((response) => {
                self.$loading(false)
              })
              .catch((error) => {
                self.$loading(false);
                self.sending_message = false;
                self.$message(null, error.response.data, "error");
              });
            break;
        }
      });
    },
    toggleCollapse() {
      this.showCollapse = !this.showCollapse;
    },
    rolar() {
      setTimeout(() => {
        $("#chat-messages").scrollTop($("#chat-messages")[0].scrollHeight);
      }, 500);
    },
    finalizar() {
      const self = this;
      swal({
        title: "Deseja finalizar o atendimento?",
        text: "Esta alteração não pode ser desfeita.",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#DD6B55",
        confirmButtonText: "Sim, deletar!",
        showLoaderOnConfirm: true,
        buttons: {
          catch: {
            text: "Não",
            value: "cancel",
            className: "btn btn-danger",
          },
          confirm: {
            text: "Sim",
            value: "confirm",
            className: "btn btn-success",
          },
        },
      }).then((value) => {
        switch (value) {
          case "cancel":
            swal("Ok!", "O atendimento não foi finalizado.", "info");
            break;

          case "confirm":
            self.$loading(true);
            let api = self.$store.state.api + "ocorrencia";

            const payload = {
              data_fim_atendimento: moment().format("YYYY-MM-DD HH:mm:ss"),
              id: self.atendimento.ocorrencia_id,
              status: self.atendimento.status,
            };

            api += "/" + self.atendimento.ocorrencia_id;

            axios
              .put(api, payload)
              .then((response) => {
                axios
                  .put(
                    self.$store.state.api +
                    `ocorrencias/${self.atendimento.ocorrencia_id}/finalizar`
                  )
                  .then((response) => {
                    self.message = "";
                    self.sending_message = false;
                    self.getMessages();
                    swal(
                      "Atendimento finalizado",
                      response.data.mensagem,
                      "success"
                    );

                    setTimeout(() => {
                      $("#message_area").focus();
                    }, 500);
                    self.$router.push("/atendimentos");
                  })
                  .catch((error) => {
                    self.$loading(false);
                    self.$message(null, error.response.data, "error");
                  });
              })
              .catch((error) => {
                self.$loading(false);
                self.sending_message = false;
                self.$message(null, error.response.data, "error");
              });
            break;
        }
      });
    },
    iniciarAtendimento() {
      const self = this;
      swal({
        title: "Deseja iniciar o atendimento?",
        text: "Você será o responsável por este atendimento.",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#DD6B55",
        confirmButtonText: "Sim, deletar!",
        showLoaderOnConfirm: true,
        buttons: {
          catch: {
            text: "Não",
            value: "cancel",
            className: "btn btn-danger",
          },
          confirm: {
            text: "Sim",
            value: "confirm",
            className: "btn btn-success",
          },
        },
      }).then((value) => {
        switch (value) {
          case "cancel":
            swal(
              "Ok!",
              "Você será redirecionado para a página de atendimentos pendentes.",
              "info"
            );
            self.$router.push("/atendimentos");
            break;

          case "confirm":
            self.$loading(true);
            let api = self.$store.state.api + "ocorrencia";

            const payload = {
              data_inicio_atendimento: moment().format("YYYY-MM-DD HH:mm:ss"),
              id: self.atendimento.ocorrencia_id,
              usuario_id_atendimento: self.user.usuario_id,
              status: 1,
            };

            api += "/" + self.atendimento.ocorrencia_id;

            axios
              .put(api, payload)
              .then((response) => {
                self.getAtendimento(self.atendimento.ocorrencia_id);
              })
              .catch((error) => {
                self.$loading(false);
                self.$message(null, error.response.data, "error");
              });
            break;
        }
      });
    },
    sendMessage: function () {
      const self = this;
      const api = self.$store.state.api + "chat/send-message";

      self.sending_message = true;

      axios
        .post(api, {
          mensagem: self.message,
          ocorrencia_id: self.$route.params.id,
        })
        .then((response) => {
          self.message = "";
          self.sending_message = false;
          self.getMessages();

          setTimeout(() => {
            $("#message_area").focus();
          }, 500);
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
          self.sending_message = false;
        });
    },
    playSound(volume) {
      var audio = new Audio(
        "https://soundbible.com/mp3/Air%20Plane%20Ding-SoundBible.com-496729130.mp3"
      );
      if (volume == null || volume == undefined) {
        volume = 0;
      }
      audio.volume = volume;
      audio.play();
    },
    getMessages() {
      const self = this;

      const api =
        self.$store.state.api +
        "chat_messages?paginated=false&with[]=usuario&ocorrencia_id=" +
        self.atendimento.ocorrencia_id;
      axios
        .get(api)
        .then((response) => {
          self.messages = response.data.data;

          self.loaded = true;
          self.rolar();
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
          self.$loading(false);
        });
    },
    getStatus() {
      const self = this;
      const api = self.$store.state.api + "status?paginated=false";
      axios
        .get(api)
        .then((response) => {
          self.status = response.data.data;
          self.statusList = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    countdown() {
      const self = this;
      self.segundos++;
      if (self.segundos == 60) {
        self.segundos = 0;
        self.minutos++;
      }
      if (self.minutos == 60) {
        self.minutos = 0;
        self.horas++;
      }
      $("#timer").html(
        (this.horas ? this.horas : "00") +
        ":" +
        (this.minutos
          ? this.minutos > 9
            ? this.minutos
            : "0" + this.minutos
          : "00") +
        ":" +
        (this.segundos > 9 ? this.segundos : "0" + this.segundos)
      );
    },
    getAtendimento(id) {
      const self = this;

      const api =
        self.$store.state.api +
        "ocorrencia?with[]=paciente&with[]=form&with[]=solicitante&ocorrencia_id=" +
        id;
      axios
        .get(api)
        .then((response) => {
          self.atendimento = response.data.data[0];
          self.position.lat = parseFloat(self.atendimento.latitude);
          self.position.lng = parseFloat(self.atendimento.longitude);
          self.$loading(false);

          self.getMessages();
          self.getStatus();

          if (self.atendimento.data_inicio_atendimento == null) {
            self.iniciarAtendimento();
          }

          self.horas = Math.floor(self.atendimento.chronometer / 3600);
          self.minutos = Math.floor((self.atendimento.chronometer % 3600) / 60);
          self.segundos = self.atendimento.chronometer % 60;

          if (self.atendimento.qtd_messages > 0) {
            self.$message(
              "Atenção",
              "Existem mensagens não lidas no chat deste atendimento.",
              "warning"
            );
          }

          setTimeout(() => {
            self.getHistoricoStatus();
          }, 2000);

          // self.countdown();

          if (!self.websocket_listening) {
            setTimeout(() => {
              self.websocket_listening = true;
              Echo.private(
                "ocorrencia." + self.atendimento.ocorrencia_id
              ).listen(".Alert", (data) => {
                var data_message = JSON.parse(data.message);
                console.log(JSON.parse(data.message));
                if (self.user.usuario_id != data_message.usuario_id) {
                  self.messages.push(JSON.parse(data.message));
                }
                setTimeout(() => {
                  if (self.user.usuario_id != data_message.usuario_id) {
                    if (Notification.permission === "granted") {
                      new Notification("Chat - App SAMU IT4D", {
                        body:
                          "Nova mensagem no chat com " +
                          self.atendimento.solicitante.usuario_nome,
                        icon: "@/assets/images/it4d-e-samu.png",
                      });
                    }

                    self.playSound(0.5);
                    self.$message(
                      "Chat",
                      "Nova mensagem no chat com " +
                      self.atendimento.solicitante.usuario_nome,
                      "info",
                      "/atendimentos/view/" + self.atendimento.ocorrencia_id
                    );
                  }
                  $("#chat-messages").scrollTop(
                    $("#chat-messages")[0].scrollHeight
                  );
                }, 100);
              });
            }, 1000);
          }
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
          self.$loading(false);
        });
    },
    getHistoricoStatus() {
      const self = this;
      const api =
        self.$store.state.api +
        "ocorrencias/" +
        self.atendimento.ocorrencia_id +
        "/historico-status";
      axios
        .get(api)
        .then((response) => {
          const historicoStatusList = response.data.data;

          self.historico_status = historicoStatusList.map((historico) => {
            const statusIdAnterior = historico.historico_status_anterior;
            const statusIdAtual = historico.historico_status_atual;

            const statusAnterior = self.statusList.find(
              (status) => status.id === statusIdAnterior
            );
            const statusAtual = self.statusList.find(
              (status) => status.id === statusIdAtual
            );

            return {
              ...historico,
              nome_status_anterior: statusAnterior
                ? statusAnterior.nome
                : "Solicitado",
              nome_status_atual: statusAtual ? statusAtual.nome : "Solicitado",
            };
          });
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    save() {
      const self = this;
      self.$loading(true);
      let api = self.$store.state.api + "ocorrencia";

      const payload = {
        status: self.atendimento.status,
        id: self.atendimento.ocorrencia_id,
        desfecho: self.atendimento.desfecho,
      };

      api += "/" + self.atendimento.ocorrencia_id;

      axios
        .put(api, payload)
        .then((response) => {
          self.$message(
            "Sucesso",
            `Informações guardadas com sucesso`,
            "success"
          );
          self.$loading(false);
          // this.$router.push("/atendimentos");
        })
        .catch((error) => {
          self.$loading(false);
          self.$message(null, error.response.data, "error");
        });
    },
    readMessages: function () {
      const self = this;
      const api = self.$store.state.api + "chat/read-messages";

      axios
        .post(api, {
          ocorrencia_id: self.atendimento.ocorrencia_id,
        })
        .then((response) => { })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
  },
};
</script>
<style scoped>
/* CUSTOMIZACOES VUE LOADING */
.custom-loading-overlay .vld-icon {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
  backdrop-filter: blur(3px);
  opacity: 0.98;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.custom-loading-spinner {
  width: 100px;
  height: 100px;
  z-index: 10000;
}

.profile_user {
  text-align: center;
}

.photo {
  display: block;
  margin-left: 5px;
  width: 18%;
  border-radius: 10px;
  max-width: 400px;
  max-height: 400px;
  object-fit: cover;
}

.btn-user:hover,
.btn-user[aria-expanded="true"] {
  background: linear-gradient(to right, #000, #666);
  color: #fff !important;
}

.photo-title {
  color: #000;
  font-size: 18px;
  display: block;
  margin-left: 5px;
  width: 18%;
}

.custom-input {
  border-radius: 0;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.input-group>.form-control {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
}

.input-group>.form-control:focus {
  z-index: 3;
}

.custom-input-container {
  position: relative;
  font-size: 0.857rem !important;
}

.custom-input {
  padding: 0.375rem 0.75rem;
  height: 2.714rem;
  font-size: 0.857rem !important;
  font-weight: normal;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  /* box-shadow: 0 0.2rem 0.5rem rgba(0, 0, 0, 0.15); */
  width: 100%;
}

.custom-input:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

/* CSS do Chat */

.chat .chat-header {
  padding: 15px 20px !important;
  border-bottom: 2px solid #f4f7f6;
}

.chat .chat-header img {
  float: left !important;
  border-radius: 40px !important;
  width: 40px;
}

.chat .chat-header .chat-about {
  float: left !important;
  padding-left: 10px;
}

.chat .empty {
  min-height: 80vh;
  padding: 10px;
}

.chat .chat-history {
  padding: 20px !important;
  border-bottom: 2px solid #fff;
}

.chat .chat-history ul {
  padding: 0;
}

.chat .chat-history ul li {
  list-style: none !important;
  margin-bottom: 30px;
}

.chat .chat-history ul li:last-child {
  margin-bottom: 0px;
}

.chat .chat-history .message-data {
  margin-bottom: 15px;
}

.chat .chat-history .message-data img {
  border-radius: 40px !important;
  width: 40px;
}

.chat .chat-history .message-data-time {
  color: #434651 !important;
  padding-left: 6px;
}

.chat .chat-history .message {
  color: #444 !important;
  padding: 18px 20px !important;
  line-height: 26px !important;
  font-size: 16px !important;
  border-radius: 7px !important;
  display: inline-block !important;
  position: relative;
}

.chat .chat-history .message:after {
  bottom: 100% !important;
  left: 7% !important;
  border: solid transparent !important;
  content: " " !important;
  height: 0 !important;
  width: 0 !important;
  position: absolute !important;
  pointer-events: none !important;
  border-bottom-color: #fff !important;
  border-width: 10px !important;
  margin-left: -10px;
}

.chat .chat-history .my-message {
  background: #efefef;
  min-width: 15%;
}

.chat .chat-history .my-message:after {
  bottom: 100% !important;
  left: 30px !important;
  border: solid transparent !important;
  content: " " !important;
  height: 0 !important;
  width: 0 !important;
  position: absolute !important;
  pointer-events: none !important;
  border-bottom-color: #efefef !important;
  border-width: 10px !important;
  margin-left: -10px;
}

.chat .chat-history .other-message {
  background: #e8f1f3 !important;
  text-align: right;
  min-width: 15%;
}

.chat .chat-history .other-message:after {
  border-bottom-color: #e8f1f3 !important;
  left: 80% !important;
}

.chat .chat-message {
  padding: 20px;
}

.online,
.offline,
.me {
  margin-right: 2px !important;
  font-size: 8px !important;
  vertical-align: middle;
}

.online {
  color: #86c541;
}

.offline {
  color: #e47297;
}

.me {
  color: #1d8ecd;
}

.float-right {
  float: right;
}

.clearfix:after {
  visibility: hidden !important;
  display: block !important;
  font-size: 0 !important;
  content: " " !important;
  clear: both !important;
  height: 0;
}

.historico-container {
  margin-top: 20px;
}

.historico-item {
  border: 1px solid #ccc;
  padding: 10px;
  margin-bottom: 10px;
  background-color: #f7f7f7;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.status-info,
.data-info {
  flex: 1;
}

.status-label,
.data-label {
  font-weight: bold;
}

.status-name,
.data-value {
  color: #333;
}
</style>